import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Alert, Card, Image, InputGroup, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import useAuth from '../hooks/useAuth';
import logo from '../assets/recargacreditos-02.svg';
import backgroundImage from '../assets/fondo.webp';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [identificador, setIdentificador] = useState('');
  const [contrasenia, setContrasenia] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [recuperar, setRecuperar] = useState(false);
  const [correoRecuperacion, setCorreoRecuperacion] = useState('');
  const [codigo, setCodigo] = useState('');
  const [nuevaContrasenia, setNuevaContrasenia] = useState('');
  const [recuperacionPaso, setRecuperacionPaso] = useState(1);
  const { login, isAuthenticated, authService, confirmarCodigoRecuperacion } = useAuth();
  const navigate = useNavigate();

  const [hasEditedIdentificador, setHasEditedIdentificador] = useState(false);
  const [hasEditedContrasenia, setHasEditedContrasenia] = useState(false);
  const [hasEditedCorreoRecuperacion, setHasEditedCorreoRecuperacion] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(true);
  const [recaptchaInstance, setRecaptchaInstance] = useState(null);

  // Función para cargar el reCAPTCHA de manera segura
  const loadReCaptcha = useCallback(() => {
    try {
      if (window.grecaptcha && window.grecaptcha.ready) {
        window.grecaptcha.ready(() => {
          setShowRecaptcha(true);
        });
      } else {
        console.warn('reCAPTCHA no está disponible');
      }
    } catch (err) {
      console.error('Error al inicializar reCAPTCHA:', err);
      setError('Error al inicializar el sistema de verificación.');
    }
  }, []);

  useEffect(() => {
    loadReCaptcha();
    return () => {
      // Limpieza al desmontar
      if (recaptchaInstance) {
        recaptchaInstance.reset();
      }
    };
  }, [loadReCaptcha]);

  const handleRecaptchaChange = async (token) => {
    if (!token) {
      setError('Error en la verificación. Por favor, intenta de nuevo.');
      return;
    }

    try {
      const response = await axios.post(
        'https://www.recargacreditos.com.mx/api/auth/validar-recaptcha',
        { token }
      );

      if (response.data.success) {
        setRecaptchaVerified(true);
        setShowRecaptcha(false);
        setError('');
      } else {
        setError('Error en la validación de seguridad.');
        if (recaptchaInstance) {
          recaptchaInstance.reset();
        }
      }
    } catch (err) {
      console.error('Error al validar reCAPTCHA:', err);
      setError('Error en la validación. Por favor, intenta de nuevo.');
      if (recaptchaInstance) {
        recaptchaInstance.reset();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaVerified) {
      setError('Por favor, completa la verificación de seguridad.');
      return;
    }

    try {
      await login({ identificador, contrasenia });
      if (isAuthenticated) {
        navigate('/');
      }
    } catch (error) {
      console.error('Error de inicio de sesión:', error);
      setError('Error durante el inicio de sesión. '+ error.message);
      // Reset reCAPTCHA en caso de error
      if (recaptchaInstance) {
        recaptchaInstance.reset();
        setRecaptchaVerified(false);
        setShowRecaptcha(true);
      }
    }
  };

  // Resto de los manejadores de eventos
  const handleCodigoChange = (e) => {
    const input = e.target.value;
    if (/^\d{0,4}$/.test(input)) {
      setCodigo(input);
      setError('');
    }
  };

  const handleNuevaContraseniaChange = (e) => {
    setNuevaContrasenia(e.target.value);
    setError('');
  };

  const handleIdentificadorChange = (e) => {
    setIdentificador(e.target.value);
    setHasEditedIdentificador(true);
    setError('');
  };

  const handleContraseniaChange = (e) => {
    setContrasenia(e.target.value);
    setHasEditedContrasenia(true);
    setError('');
  };

  const handleCorreoRecuperacionChange = (e) => {
    setCorreoRecuperacion(e.target.value);
    setHasEditedCorreoRecuperacion(true);
    setError('');
  };

  const solicitarCodigoRecuperacion = async (correo) => {
    try {
      const response = await axios.post(
        'https://www.recargacreditos.com.mx/api/auth/recuperar-contrasenia',
        { correo }
      );
      return response.data.message;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al solicitar el código de recuperación');
    }
  };

  const solicitarCodigo = async () => {
    try {
      const message = await solicitarCodigoRecuperacion(correoRecuperacion);
      setMensaje(message);
      setRecuperacionPaso(2);
    } catch (err) {
      setError(err.message);
    }
  };

  const confirmarCodigo = async () => {
    try {
      const result = await confirmarCodigoRecuperacion({
        correo: correoRecuperacion,
        codigoVerificacion: codigo,
        nuevaContrasenia: nuevaContrasenia,
      });

      if (result.user) {
        navigate('/');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const containerStyle = {
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (!recaptchaVerified) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{     minHeight: '100vh',
        minWidth: '100vw',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' }}>
        <Modal show={showRecaptcha} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title>Verifica que no eres un robot</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            {error && <Alert variant="danger" className="mb-3">{error}</Alert>}
            <ReCAPTCHA
              ref={ref => setRecaptchaInstance(ref)}
              sitekey="6Leq_IkqAAAAAEnjTj_8esW0X6swtNGZfKhNqLV3"
              onChange={handleRecaptchaChange}
              onErrored={() => setError('Error al cargar la verificación. Por favor, recarga la página.')}
              onExpired={() => {
                setError('La verificación ha expirado. Por favor, intenta de nuevo.');
                setRecaptchaVerified(false);
              }}
            />
          </Modal.Body>
        </Modal>
      </Container>
    );
  }

  if (isAuthenticated) {
    navigate('/');
    return null;
  }

  // El resto del JSX permanece igual...
  return (
    <Container className="d-flex justify-content-center align-items-center" style={containerStyle}>
      <Card style={{ width: '100%', maxWidth: '400px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
        <Card.Header className="text-center" style={{ backgroundColor: '#009bdc', borderRadius: '10px 10px 0 0', padding: '15px 0' }}>
          <Image src={logo} style={{ width: '180px' }} alt="Logotipo" />
        </Card.Header>
        {recuperar ? (
          <Card.Body>
            <h2 className="text-center mb-4" style={{ color: '#0A74DA' }}>Recuperar Contraseña</h2>
            {mensaje && <Alert variant="success">{mensaje}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            {recuperacionPaso === 1 && (
              <>
                <Form.Group controlId="correoRecuperacion">
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Ingresa tu correo"
                    value={correoRecuperacion}
                    onChange={handleCorreoRecuperacionChange}
                    required
                  />
                </Form.Group>
                <Button onClick={solicitarCodigo} variant="primary" style={{ width: '100%', marginTop: '20px' }}>
                  Solicitar Código
                </Button>
              </>
            )}
            {recuperacionPaso === 2 && (
              <>
                <Form.Group controlId="codigo">
                  <Form.Label>Código de Verificación</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa el código recibido"
                    value={codigo}
                    onChange={handleCodigoChange}
                    required
                    maxLength="4"
                  />
                </Form.Group>
                <Form.Group controlId="nuevaContrasenia" className="mt-3">
                  <Form.Label>Nueva Contraseña</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showNewPassword ? 'text' : 'password'}
                      placeholder="Ingresa tu nueva contraseña"
                      value={nuevaContrasenia}
                      onChange={handleNuevaContraseniaChange}
                      required
                    />
                    <Button variant="outline-secondary" onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                </Form.Group>
                <Button onClick={confirmarCodigo} variant="primary" style={{ width: '100%', marginTop: '20px' }}>
                  Confirmar y Cambiar Contraseña
                </Button>
              </>
            )}
            <Button onClick={() => {
              setRecuperar(false);
              setError('');
            }} variant="link" style={{ marginTop: '10px', width: '100%' }}>
              Volver al inicio de sesión
            </Button>
          </Card.Body>
        ) : (
          <Card.Body style={{ padding: '20px 30px' }}>
            <h2 className="text-center mb-4" style={{ color: '#0A74DA' }}>Iniciar Sesión</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicIdentificador">
                <Form.Label>Nombre de tienda o Correo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de tienda o Correo"
                  value={identificador}
                  onChange={handleIdentificadorChange}
                  required
                  style={{ borderColor: '#ddd', borderRadius: '5px' }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="mt-3">
                <Form.Label>Whatsapp o Contraseña</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Whatsapp o Contraseña"
                    value={contrasenia}
                    onChange={handleContraseniaChange}
                    required
                    style={{ borderColor: '#ddd', borderRadius: '5px' }}
                  />
                  <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                </InputGroup>
              </Form.Group>
              <Button variant="primary" type="submit" style={{ width: '100%', marginTop: '20px', backgroundColor: '#0A74DA', borderColor: '#0A74DA' }}>
                Iniciar Sesión
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  setRecuperar(true);
                  setError('');
                }}
                style={{ marginTop: '10px', width: '100%' }}
              >
                ¿Olvidaste tu contraseña?
              </Button>
            </Form>
          </Card.Body>
        )}
      </Card>
    </Container>
  );
};

export default Login;