import React, { useState, useEffect } from 'react';
import { Container, Table, Row, Col, Dropdown, DropdownButton, Button, Pagination, ButtonGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import api from '../../services/axiosConfig';

const ManageSaldo = () => {
  const [saldosAcreditados, setSaldosAcreditados] = useState([]);
  const [saldoDisponible, setSaldoDisponible] = useState(0);
  const [credito, setCredito] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState({ column: 'fecha', order: 'desc' });
  const [showPagados, setShowPagados] = useState(false);

  useEffect(() => {
    fetchSaldos();
  }, []);

  const fetchSaldos = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get('https://www.recargacreditos.com.mx/api/tiendas/saldo', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSaldoDisponible(response.data.saldo_disponible || 0);
      setSaldosAcreditados(response.data.saldos_acreditados || []);
      setCredito(response.data.credito || 0);
    } catch (error) {
      console.error('Error al obtener los saldos', error);
    }
  };

  const handleSort = (column) => {
    const newOrder = sortOrder.order === 'asc' ? 'desc' : 'asc';
    setSortOrder({ column, order: newOrder });
  };
  const handleKeyDown = (e) => {
    const validKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', '/'];
    const isNumber = /^[0-9]$/.test(e.key);

    // Si la tecla presionada no es un número, una tecla de navegación válida o "/"
    if (!isNumber && !validKeys.includes(e.key)) {
      e.preventDefault(); // Prevenir la entrada de cualquier otro carácter
    }
  };

  const handleCreditoFilter = () => {
    setShowPagados(!showPagados);
  };

  const formatValue = (value) => (typeof value === 'number' ? value.toFixed(2) : '');

  const filteredSaldos = saldosAcreditados
    .filter(saldo => 
      (!startDate || new Date(saldo.fecha) >= startDate) &&
      (!endDate || new Date(saldo.fecha) <= endDate) &&
      (!showPagados || !saldo.credito)
    )
    .sort((a, b) => {
      if (sortOrder.column === 'fecha') {
        return sortOrder.order === 'asc' ? new Date(a.fecha) - new Date(b.fecha) : new Date(b.fecha) - new Date(a.fecha);
      }
      return 0;
    });

  const totalPages = Math.ceil(filteredSaldos.length / pageSize);
  const paginatedSaldos = filteredSaldos.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Saldos Acreditados');
  
    worksheet.columns = [
      { header: 'Valor', key: 'valor', width: 15 },
      { header: 'Acreditado', key: 'acreditado', width: 15 },
      { header: 'Crédito', key: 'restante', width: 15 },
      { header: 'Fecha', key: 'fecha', width: 20 },
      { header: 'Estado', key: 'estado', width: 20 }
    ];
  
    filteredSaldos.forEach((saldo) => {
      // Ajustar la lógica para determinar el estado de la transacción como en el frontend
      let estadoTransaccion;
  
      if (saldo.valor < 0) {
        estadoTransaccion = 'Corrección de Saldo';
      } else if (saldo.credito) {
        estadoTransaccion = 'Saldo pagado';
      } else {
        estadoTransaccion = 'Saldo puesto';
      }
  
      worksheet.addRow({
        valor: formatValue(saldo.valor),
        acreditado: formatValue(saldo.valor_con_porcentaje),
        restante: formatValue(saldo.valor_restante),
        fecha: saldo.fecha ? new Date(saldo.fecha).toLocaleString() : '',
        estado: estadoTransaccion, // Usa el mismo estado que se muestra en la tabla
      });
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), 'SaldosAcreditados.xlsx');
  };
  
  return (
    <Container>
      {/* UI Elements */}
      <Row className="my-4">
        <Col xs={6} sm={8} md={8} lg={8}>
          <h1 className="text-left" style={{ color: '#0A74DA' }}>Pagos</h1>
        </Col>
        <Col   xs={6}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-end align-items-center">
          <Button variant="success" onClick={exportToExcel}>Exportar a Excel</Button>
        </Col>
      </Row>
      {/* Filters and Dropdowns */}
      <div md={12}>
      <Row className="mb-3" >
        <Col className="d-flex justify-content-between align-items-center" style={{ gap: '10px', flexWrap: 'nowrap', width: '100%' }}>
        <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      placeholderText="Fecha de inicio"
      dateFormat="dd/MM/yyyy"
      maxDate={endDate || new Date()}
      isClearable
      className="form-control w-100" 
      onKeyDown={handleKeyDown}
      popperPlacement="bottom-start"
      portalId="root-portal"
    />
    {/* Segundo DatePicker (Fecha de fin) */}
    <DatePicker
      selected={endDate}
      onChange={(date) => setEndDate(date)}
      placeholderText="Fecha de fin"
      dateFormat="dd/MM/yyyy"
      minDate={startDate}
      maxDate={new Date()}
      isClearable
      className="form-control w-100" 
      onKeyDown={handleKeyDown}
      inputReadOnly
      popperPlacement="bottom-end"
      portalId="root-portal"
    />
          <DropdownButton
            variant="outline-secondary"
            title={`Mostrar ${pageSize}`}
            id="input-group-dropdown-2"
            onSelect={(e) => setPageSize(e === 'all' ? filteredSaldos.length : parseInt(e))}
            style={{ flex: 1 }}
          >
            <Dropdown.Item eventKey="10">10</Dropdown.Item>
            <Dropdown.Item eventKey="20">20</Dropdown.Item>
            <Dropdown.Item eventKey="50">50</Dropdown.Item>
            <Dropdown.Item eventKey="100">100</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="all">Todo</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      </div>
      {/* Tabla para pantallas grandes */}
<Table hover className="custom-table d-none d-md-table">
  <thead>
    <tr>
      <th onClick={() => handleSort('fecha')} style={{ cursor: 'pointer' }}>
        Fecha {sortOrder.column === 'fecha' && (sortOrder.order === 'asc' ? '↑' : '↓')}
      </th>
      <th>Valor</th>
      <th>Acreditado</th>
      {/* <th>Crédito</th> */}
      <th onClick={handleCreditoFilter} style={{ cursor: 'pointer' }}>
        Tipo de transacción
      </th>
    </tr>
  </thead>
  <tbody>
    {paginatedSaldos.map((saldo, index) => {
      // Verificación del estado de la transacción
      let estadoTransaccion;
      let estadoColor;

      if (saldo.valor < 0) {
        if (saldo.credito) {
          estadoTransaccion = 'Corrección de Credito';
          estadoColor = 'red';
        }  else {
          estadoTransaccion = 'Corrección de Saldo';
          estadoColor = 'red';
        }
       
      } else if (saldo.credito) {
        estadoTransaccion = 'Saldo pagado';
        estadoColor = 'green';
      } else {
        estadoTransaccion = 'Saldo puesto';
        estadoColor = 'orange';
      }

      // Formateo de valores
      const formatValue = (value) => (typeof value === 'number' ? value.toFixed(2) : '');

      return (
        <tr key={index}>
<td>{saldo.fecha ? new Date(saldo.fecha).toLocaleString('es-ES', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
}) : ''}   </td>       <td>{formatValue(saldo.valor)}</td>
          <td>{formatValue(saldo.valor_con_porcentaje)}</td>
          {/* <td>{formatValue(saldo.valor_restante)}</td> */}
          <td style={{ color: estadoColor }}>{estadoTransaccion}</td>
        </tr>
      );
    })}
  </tbody>
</Table>
{/* Tarjetas para pantallas pequeñas */}
<div className="d-md-none">
  {paginatedSaldos.map((saldo, index) => {
    // Verificación del estado de la transacción
    let estadoTransaccion;
    let estadoColor;

    if (saldo.valor < 0) {
      if (saldo.credito) {
        estadoTransaccion = 'Corrección de Credito';
        estadoColor = 'red';
      }  else {
        estadoTransaccion = 'Corrección de Saldo';
        estadoColor = 'red';
      }
    } else if (saldo.credito) {
      estadoTransaccion = 'Saldo pagado';
      estadoColor = 'green';
    } else {
      estadoTransaccion = 'Saldo puesto';
      estadoColor = 'orange';
    }

    // Formateo de valores
    const formatValue = (value) => (typeof value === 'number' ? value.toFixed(2) : '');

    return (
      <div key={index} className="saldo-card">
        <div className="saldo-info">
          <div className="info-item">
          <strong>Fecha:</strong> {saldo.fecha ? new Date(saldo.fecha).toLocaleString('es-ES', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
}) : ''}          </div>
          <div className="info-item">
            <strong>Valor:</strong> {formatValue(saldo.valor)}
          </div>
          <div className="info-item">
            <strong>Acreditado:</strong> {formatValue(saldo.valor_con_porcentaje)}
          </div>
        </div>
        <div className="saldo-info">
          {/* <div className="info-item">
            <strong>Crédito:</strong> {formatValue(saldo.valor_restante)}
          </div> */}
          <div className="info-item double-space">
            <strong>Tipo de transacción:</strong> 
            <span style={{ color: estadoColor }}>{estadoTransaccion}</span>
          </div>
        </div>
      </div>
    );
  })}
</div>

      {/* Pagination */}
      <Pagination className="justify-content-center">
        <ButtonGroup>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index + 1}
              variant={index + 1 === currentPage ? 'primary' : 'outline-primary'}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Button>
          ))}
        </ButtonGroup>
      </Pagination>
      {/* Balance Info */}
      <div className="mt-4 text-right">
        <strong>Saldo Disponible:</strong> ${formatValue(saldoDisponible)}
      </div>
      <div className="mt-4 text-right">
        <strong>Credito:</strong> ${formatValue(credito)}
      </div>
      {/* Styles */}

{/* Estilos ajustados para la versión móvil */}
{/* Estilos ajustados para la versión móvil */}
<style>{`
  .saldo-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
  }
  .saldo-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  .info-item {
    flex: 1 1 30%; /* Cada elemento ocupa aproximadamente un tercio del ancho */
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    padding: 5px;
  }
  .info-item.double-space {
    flex: 2 1 60%; /* Este elemento ocupa el doble de espacio */
  }
  .info-item strong {
    text-align: left;
    margin-bottom: 4px;
  }
  @media (max-width: 768px) {
    .saldo-info {
      align-items: flex-start;
    }
  }
`}</style>

    </Container>
  );
};

export default ManageSaldo;
